import React from "react"

import PageLayout from "../components/pageLayout/index"
import SEOHelmet from "../components/SEOHelmet/index"

const PrivacyPage = () => (
  <PageLayout hasArrows={false}>
    <SEOHelmet title="Privacy" />
    <div className="page-div-padding privacy-page page-padding">
      <h2>Legal Disclaimer & Privacy Policy</h2>
      <h3>Legal disclaimer</h3>
      <p>
        Please read this disclaimer carefully before using the website
        danielagerstmann.com.
      </p>
      <p>
        The content displayed on the website is the intellectual property of me,
        Daniela Gerstmann, except where credited otherwise. I herewith assert my
        moral rights in my work. You may not reuse, republish, or reprint any
        content from my website without my written consent.
      </p>
      <p>
        All information provided is merely for educational and informational
        purposes. It is not intended as a substitute for professional advice.
        Should you decide to act upon any information on this website, you do so
        at your own risk.
      </p>
      <p>
        While the information on this website has been verified to the best of
        my ability, I cannot guarantee that there are no mistakes or errors. By
        using my website, you assume all responsibility and risk for the use of
        this website, any linked third-party content and the Internet generally.
        I do not accept liability to any person as a consequence on any reliance
        upon the information contained in this site.
      </p>
      <p>
        I reserve the right to change this policy at any given time and will
        place any updates on this web page. The disclaimer was last updated on 1
        November 2020.
      </p>

      <h3>Privacy Policy</h3>
      <p>
        This privacy policy will explain how I use the personal data collected
        from you when you use my website, in accordance with the EU General Data
        Protection Regulation (GDPR).
      </p>
      <p>Topics:</p>
      <ul>
        <li>What kind of data does my website collect?</li>
        <li>How do I collect your data and how will I use it?</li>
        <li>How do I store your data?</li>
        <li>What are your data protection rights?</li>
        <li>What are cookies?</li>
        <li>How do I use cookies?</li>
        <li>What types of cookies does this website use?</li>
        <li>How to manage your cookies</li>
        <li>Privacy policies of other websites</li>
        <li>Changes to this privacy policy</li>
        <li>How to contact me</li>
        <li>How to contact the appropriate authorities</li>
      </ul>

      <h4>What data does my website collect?</h4>
      <p>
        This website does not collect data, except via cookies, from you. Please
        see below for information about cookies.
      </p>

      <h4>How do I collect your data and how will I use it?</h4>
      <p>
        This website uses cookies to ensure its functionality, for example, to
        embed YouTube videos. This website does not collect data, except via
        cookies, from you. Please see below for information about cookies.
      </p>

      <h4>How do I store your data?</h4>
      <p>
        I do not store your data. Please see below for information about
        cookies.
      </p>

      <h4>What are your data protection rights?</h4>
      <p>
        While I do not collect and process your personal data, I would like to
        make sure you are fully aware of all of your data protection rights.
        These are: the right to access, the right to rectification, the right to
        erasure, the right to restrict processing, the right to object to
        processing and the right to data portability.
      </p>
      <p>
        To learn more about your rights, visit{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
        >
          ico.org.uk/for-organisations/guide-to-data-protection
        </a>
        .
      </p>

      <h4>Cookies</h4>
      <p>
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behaviour information. When you
        visit my websites, I may collect information from you automatically
        through cookies.
      </p>
      <p>
        For further information, visit{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.allaboutcookies.org/"
        >
          www.allaboutcookies.org
        </a>
        .
      </p>

      <h4>How do I use cookies?</h4>
      <p>
        This website uses cookies to improve your experience on the website.
      </p>

      <h4>What types of cookies does this website use?</h4>
      <p>
        There are a number of different types of cookies, however, this website
        uses cookies that insure its functionality, including the playing of
        YouTube videos. A mix of first-party and third-party cookies are used.
      </p>

      <h4>How to manage cookies</h4>
      <p>
        You can set your browser not to accept cookies, and the above website
        tells you how to remove cookies from your browser. However, in a few
        cases, some of my website features may not function as a result.
      </p>

      <h4>Privacy policies of other websites</h4>
      <p>
        My website contains links to other websites. My privacy policy applies
        only to my website, so if you click on a link to another website, you
        should read their privacy policy.
      </p>

      <h4>Changes to my privacy policy</h4>
      <p>
        I keep this privacy policy under regular review and place any updates on
        this web page. This privacy policy was last updated on 1 November 2020.
      </p>

      <h4>How to contact me</h4>
      <p>
        If you have any questions about my privacy policy, please do not
        hesitate to contact me. Email me at: <i>mail@danielagerstmann.com</i>.
      </p>

      <h4>How to contact the appropriate authority</h4>
      <p>
        Should you wish to report a complaint or if you feel that I have not
        addressed your concern in a satisfactory manner, you may contact the
        Information Commissioner’s Office. You can find their contact
        information here:{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://ico.org.uk/global/contact-us"
        >
          ico.org.uk/global/contact-us
        </a>
        .
      </p>
    </div>
  </PageLayout>
)

export default PrivacyPage
